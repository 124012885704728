import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { StaticImage } from "gatsby-plugin-image"

export default function about() {
  return (
    <Layout>
      <Seo
        title="Over mij"
        description="Op mijn welkompagina schreef ik al dat ik in mijn studententijd al ambities had in de richting van
                fotografie, maar niet heb doorgezet. Ik volgde een ander pad door het leven. Nu pak ik de draad van de fotografie weer op met een
                opleiding bij fotoschool Statief."
      />
      <main className="main-content main-about">
        <h1 className="main-title">Over mij</h1>
        <div className="column-left">
          <p>
            Op mijn welkompagina schreef ik al dat ik in mijn studententijd al
            ambities had in de richting van fotografie, maar niet heb doorgezet.
            Ik volgde een ander pad door het leven. Zie mijn LinkedIn profiel:{" "}
            <a
              className="link"
              href="http://www.linkedin.com/in/janwillemdezeeuw/"
              target="_blank"
              rel="noreferrer noopener"
            >
              (12) Jan Willem de Zeeuw | LinkedIn.
            </a>{" "}
            Nu pak ik de draad van de fotografie weer op met een opleiding bij
            fotoschool Statief.
          </p>
        </div>
        <div className="column-right">
          <StaticImage
            src="../images/portretten/zelfportret-2023.jpg"
            alt="zelfportret"
            placeholder="blurred"
            className="about-img"
            transformOptions={{ fit: "cover", cropFocus: "attention" }}
          />
        </div>
      </main>
    </Layout>
  )
}
